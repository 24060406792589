<template>
    <div class="q-px-md justify-center">
        <br>
        <div class="text-h5" align="center">Ricerca Clienti che hanno lo stesso numero di cellulare</div>
        <br>

        <div class="text-center q-pa-md q-gutter-sm absolute-center"
            style="z-index: 1000"
            v-show="sta_caricando">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-12 col-md-6 q-mr-sm">
                <q-input outlined v-model="Cellulare" label="Numero di cellulare" class="q-mb-sm" />
                
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-12" align="center">
                <br>
                <QQButton label="Cerca Clienti" color="blue-grey" icon="mdi-note-search-outline" size="sm" @click.native="onCercaClienti()"/>
                <hr>
                <q-table
                    title = ""
                    :data="righe"
                    :columns="colonne"
                    :filter="filter"
                    dense
                    row-key="name"
                    class="my-sticky-header-table no-box-shadow q-mt-none"
                >

                    <template v-slot:header="props">
                        <q-tr :props="props">
                            <q-th
                                v-for="col in props.cols"
                                :key="col.name"
                                :props="props"
                                style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                                <strong>{{ col.label }}</strong>
                            </q-th>
                        </q-tr>
                    </template>

                </q-table>
            </div>

            
        <br><br><br><br><br>

        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapState, mapActions } from "vuex";

    export default {
        name: "CercaCellulare",
        data() {
            return {
                sta_caricando: false,
                Cellulare: "",
                righe: [],
                colonne: [
                    { name: "IntermediarioCapogruppo", label: "Interm. Capogruppo", field: "intermediario_capogruppo", sortable: true, align: "left" },
                    { name: "Cognome", label: "Cognome", field: "cognome", sortable: true, align: "left" },
                    { name: "Nome", label: "Nome", field: "nome", sortable: true, align: "left" },
                    { name: "RagioneSociale", label: "Ragione Sociale", field: "ragione_sociale", sortable: true, align: "left" },
                    { name: "CodiceFiscale", label: "Codice Fiscale", field: "codice_fiscale", sortable: true, align: "left" },
                    { name: "PartitaIva", label: "Partita IVA", field: "partita_iva", sortable: true, align: "left" },
                ],
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {

            }),
            ...mapState({
                // formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            ...mapActions({
                fetchElencoClientiPerCellulare: "gestioneClienti/fetchElencoClientiPerCellulare"
            }),
            async onCercaClienti() {
                this.righe = [];
                this.sta_caricando = true;

                var elenco = await this.fetchElencoClientiPerCellulare(this.Cellulare);

                // scansiona per riempire le righe della tabella con tutti i risultati
                console.log("cliente:",elenco);
                elenco.forEach(cliente => {
                    this.righe.push({
                        id: cliente.id,
                        intermediario_capogruppo: cliente.intermediario_capogruppo,
                        cognome: cliente.cognome,
                        nome: cliente.nome,
                        ragione_sociale: cliente.ragione_sociale,
                        codice_fiscale: cliente.codice_fiscale,
                        partita_iva: cliente.partita_iva
                    });
                });

                this.sta_caricando = false;
            },
            //onCercaPerCellulare() {
            //    this.$router.push({name : "Clienti.CercaCellulare"});
            //},
            
        },
        async mounted() {
        },
        async activated() {
        }
    }
</script>